<template>
  <div>
    <div class="d-flex justify-content-center" v-if="parentIsLoading">
      <CSpinner color="info" />
    </div>
    <div v-else>
      <div class="overflow-hidden">
        <CRow>
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              Ordine
            </div>
            <div class="ui-type-display-sm">
              {{ orderId(order) }} - {{ orderFamilyName(order) }}
            </div>
            <div class="ui-type-body mb-2">
              <OrdersContacts
                :title="'Contatti personali'"
                :contacts="orderFamilyContacts(order)"
              />
            </div>
          </CCol>
          <CCol>
            <div class="ui-type-caption text-uppercase text-gray-600">
              Consegna
            </div>
            <div class="ui-type-display-sm">
              {{ orderDeliveryIsland(order) }}
            </div>
            <div class="ui-type-body mb-2">
              <OrdersContacts
                :title="'Contatti personali'"
                :contacts="orderDeliveryIslandContacts(order)"
              />
            </div>
          </CCol>
        </CRow>

        <CRow class="justify-content-between mb-3">
          <CCol col="auto">
            <font-awesome-icon icon="warehouse" />
            Da preparare entro
            <strong>
              {{ orderDeliveryDate(order, "dd-MM") }} alle
              {{ orderDeliveryDate(order, "HH:mm") }}</strong
            >
          </CCol>
          <CCol col="auto">
            <CButton
              color="primary"
              @click.prevent="allReady"
              :disabled="!canView(PERMS.ORDERS_EDIT)"
              >TUTTO PRONTO</CButton
            >
          </CCol>
        </CRow>
      </div>

      <CCard class="mb-2">
        <CCardBody>
          <ul class="nav nav-tabs mb-3">
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('products') }"
                @click.prevent="setView('products')"
              >
                Prodotti
              </div>
            </li>
            <li class="nav-item">
              <div
                class="nav-link"
                v-bind:class="{ active: isView('producers') }"
                @click.prevent="setView('producers')"
              >
                Produttori
              </div>
            </li>
          </ul>
          <div>
            <ProductsTable :order="order" v-if="isView('products')" />
            <ProducersTable :order="order" v-if="isView('producers')" />
          </div>
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import orderManagementMixin from "../../../../../../mixins/orderManagement";
import ProductsTable from "./ProductsTable";
import ProducersTable from "./ProducersTable";
import OrdersContacts from "../../../share/OrdersContacts";
import EventBus from "../../../../../../helpers/EventBus";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";

export default {
  name: "Detail",

  components: {
    ProductsTable,
    ProducersTable,
    OrdersContacts,
  },

  mixins: [orderManagementMixin],

  props: {
    order: { type: Object, required: true },
    parentIsLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      PERMS,
      view: "products",
    };
  },

  mounted() {},

  methods: {
    setView(view) {
      this.view = view;
    },

    isView(view) {
      return this.view === view;
    },

    allReady() {
      const orders = [this.order.order.id];
      const onSuccess = (response) => {
        EventBus.$emit("orders:refresh");
      };
      this.$store
        .dispatch("rounds/updateStatusBatch", {
          status: filtersMap("wh.statusCode.next"),
          orders,
        })
        .then(onSuccess);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
