import {
  formatDate,
  formatPrice,
  processProgressData,
} from "../helpers/common";
import {
  producersProgressMap,
  ddmiProgressMap,
  ddmiProgressMapIsland,
  whProgressMap,
  ordersProgressMap,
  incidentProgressMap,
} from "../config/global";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { isBefore } from "date-fns";

const orderManagementMixin = {
  methods: {
    producerId(record) {
      return get(record, "ddmp.producer.id");
    },

    producerName(record) {
      return get(record, "ddmp.producer.role.name");
    },

    producerProdsCount(record) {
      return get(record, "count_prods", 0);
    },

    producerOrdersCount(record) {
      return get(record, "count_orders", 0);
    },

    producerOrdersNotWeighed(record) {
      return get(record, "need_w", 0);
    },

    producerDeliveryDate(record, format = "dd-MM-yyyy HH:mm") {
      const deliveryDate = get(record, "ddmp.delivery_date", null);
      return deliveryDate ? formatDate(new Date(deliveryDate), format) : "--";
    },

    producerDeliveryDateWarning(record) {
      const deliveryDate = get(record, "ddmp.delivery_date", null);
      return deliveryDate
        ? isBefore(new Date(deliveryDate), new Date())
        : false;
    },

    producerContacts(record) {
      return get(record, "ddmp.producer.contacts");
    },

    producerPositionName(record) {
      const city = get(record, "ddmp.producer.position.city", "");
      const country = get(record, "ddmp.producer.position.country", "");
      const info = [];
      if (city) {
        info.push(city);
      }
      if (country) {
        info.push(`(${country})`);
      }
      return info.join(" ");
    },

    producerPosition(record) {
      return get(record, "ddmp.producer.position");
    },

    ddmpProgress(record) {
      const statusMap = get(record, "tot_status", {});
      const itemCount = get(record, "count_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(producersProgressMap)
      );
    },

    ddmiProgress(record) {
      const statusMap = get(record, "tot_status", {});
      const itemCount = get(record, "count_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(ddmiProgressMap)
      );
    },
    ddmiProgressIsland(record) {
      const statusMap = get(record, "tot_status", {});
      const itemCount = get(record, "count_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(ddmiProgressMapIsland)
      );
    },

    whProgress(record) {
      const statusMap = get(record, "tot_status", {});
      const itemCount = get(record, "tot_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(whProgressMap)
      );
    },

    ordersProgress(record) {
      const statusMap = get(record, "tot_status", {});
      const itemCount = get(record, "tot_prods", 0);
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(ordersProgressMap)
      );
    },

    incidentProgress(record) {
      const statusMap = get(record, "status", {});
      const itemCount = Object.values(get(record, "status", {})).reduce(
        (acc, value) => acc + value,
        0
      );
      return processProgressData(
        itemCount,
        statusMap,
        cloneDeep(incidentProgressMap)
      );
    },

    producerWarehouseName(record) {
      return get(record, "ddmp.market_wh.address.to", "--");
    },

    producerTotPrice(record) {
      const totPrice = get(record, "tot_price");
      return formatPrice(totPrice).format();
    },

    orderId(record) {
      return get(record, "order.id", "");
    },

    orderFamilyName(record) {
      return get(record, "order.family.name", "");
    },

    orderFamilyContacts(record) {
      return get(record, "order.family.contacts", []);
    },

    orderDeliveryDate(record, format = "dd-MM-yyyy HH:mm") {
      const deliveryDate = get(record, "order.ddmi.delivery_date", null);
      return deliveryDate ? formatDate(new Date(deliveryDate), format) : "--";
    },

    orderProductsCount(record) {
      return get(record, "tot_prods", 0);
    },

    orderProducersCount(record) {
      return get(record, "tot_producers", "--");
    },

    orderDeliveryIsland(record) {
      return get(record, "order.ddmi.island.role.name", "--");
    },

    orderDeliveryIslandContacts(record) {
      return get(record, "order.ddmi.island.contacts", []);
    },

    orderTotPrice(record) {
      const totPrice = get(record, "tot_price");
      return formatPrice(totPrice).format();
    },

    islandId(record) {
      return get(record, "ddmi.island.id");
    },

    islandName(record) {
      return get(record, "ddmi.island.role.name");
    },

    islandDeliveryDate(record, format = "dd-MM-yyyy HH:mm") {
      const deliveryDate = get(record, "ddmi.delivery_date", null);
      return deliveryDate ? formatDate(new Date(deliveryDate), format) : "--";
    },

    islandProdsCount(record) {
      return get(record, "count_prods", 0);
    },

    islandOrdersCount(record) {
      return get(record, "count_orders", 0);
    },

    islandContacts(record) {
      return get(record, "ddmi.island.contacts", []);
    },

    islandFamilyPositionName(record) {
      const city = get(record, "ddmi.island.position.city", "");
      const country = get(record, "ddmi.island.position.country", "");
      const info = [];
      if (city) {
        info.push(city);
      }
      if (country) {
        info.push(`(${country})`);
      }
      return info.join(" ");
    },

    islandFamilyPosition(record) {
      return get(record, "ddmi.island.position");
    },

    // orders
    ddmpMarketId(record) {
      return get(record, "ddmp.market.role.id");
    },

    ddmpMarketName(record) {
      return get(record, "ddmp.market.role.name");
    },

    ddmpMarketPositionName(record) {
      const city = get(record, "ddmp.market.position.city", "");
      const country = get(record, "ddmp.market.position.country", "");
      const info = [];
      if (city) {
        info.push(city);
      }
      if (country) {
        info.push(`(${country})`);
      }
      return info.join(" ");
    },

    ddmpMarketPosition(record) {
      return get(record, "ddmp.market.position");
    },

    ddmpMarketWarehousePosition(record) {
      const address = get(record, "ddmp.market_wh.address");
      if (address) {
        return {
          address: get(address, "address"),
          city: get(address, "city"),
          postal_code: get(address, "postal_code"),
          country: get(address, "country"),
          lat: get(address, "latitude"),
          long: get(address, "longitude"),
        };
      }
      return undefined;
    },

    ddmpMarketContacts(record) {
      return get(record, "ddmp.market.contacts");
    },

    ddmiMarketId(record) {
      return get(record, "ddmi.market.role.id");
    },

    ddmiMarketName(record) {
      return get(record, "ddmi.market.role.name");
    },

    ddmiMarketPositionName(record) {
      const city = get(record, "ddmi.market.position.city", "");
      const country = get(record, "ddmi.market.position.country", "");
      const info = [];
      if (city) {
        info.push(city);
      }
      if (country) {
        info.push(`(${country})`);
      }
      return info.join(" ");
    },

    ddmiMarketPosition(record) {
      return get(record, "ddmi.market.position");
    },

    ddmiMarketContacts(record) {
      return get(record, "ddmi.market.contacts");
    },

    incidentId(record) {
      return get(record, "id");
    },

    incidentUserFullname(record) {
      return `${get(record, "by.user.first_name")} ${get(
        record,
        "by.user.last_name"
      )}`;
    },

    incidentFamilyName(record) {
      return get(record, "by.family.name");
    },

    incidentDate(record, format = "dd-MM-yyyy HH:mm") {
      const date = get(record, "ts_created", null);
      return date ? formatDate(new Date(date), format) : "--";
    },

    incidentLastEditDate(record, format = "dd-MM-yyyy HH:mm") {
      const date = get(record, "ts_modified", null);
      return date ? formatDate(new Date(date), format) : "--";
    },

    incidentLastEditUserFullname(record) {
      return `${get(record, "mod_by.user.first_name")} ${get(
        record,
        "mod_by.user.last_name"
      )}`;
    },

    incidentLastEditFamilyName(record) {
      return get(record, "mod_by.family.name");
    },

    incidentMessage(record) {
      return get(record, "msg");
    },

    incidentPrice(record) {
      const price = get(record, "price");
      return formatPrice(price).format();
    },

    incidentProdsCount(record) {
      return get(record, "tot_details");
    },

    incidentOrdersCount(record) {
      return get(record, "tot_orders");
    },

    stripHtml(html) {
      let tmp = document.createElement("div");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
  },
};

export default orderManagementMixin;
